export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'fa fa-tachometer',
            tag: ["company","client","device"],
        },
        {
            name: 'Add Company',
            url: '/dashboard/company/add',
            icon: 'fa fa-plus-square',
            tag: ["dashboard","client","device"],
        },
        {
            name: 'Add User',
            url: '/dashboard/client/add',
            icon: 'fa fa-plus-square',
            tag: ["dashboard","company","device"],
        },
        {
            name: 'Add Device',
            url: '/dashboard/device/add',
            icon: 'fa fa-plus-square',
            tag: ["dashboard","company","client"],
        },
        {
            name: 'Users',
            url: '/dashboard/client/overview',
            icon: 'fa fa-list-alt',
            tag: ["dashboard","company","device"],
        },
        {
            name: 'Device Data',
            url: '/dashboard/device/data/latest',
            icon: 'fa fa-tachometer',
            tag: ["dashboard","company","client"],
        },
        
        // {
        //     name: 'Uitloggen',
        //     url: '/logout',
        //     icon: 'icon-logout'
        // },
    ]
}
