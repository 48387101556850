<template>
  <div class="app">

    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" :to="dashboardUrl">
        <img class="navbar-brand-full" src="/img/brand/meds.jpg" height="40" alt="Meds2go Logo">
        <!-- <img class="navbar-brand-minimized" src="/img/brand/logo.png" width="25" height="40" alt="Jani Logo"> -->
        <!-- <img class="navbar-brand-minimized" src="/img/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo"> -->
      </b-link>
      <SidebarToggler v-show="hideSidebar" class="d-md-down-none" display="lg" :defaultOpen="hideSidebar" />
      <b-navbar-nav class="d-md-down-none">
        <!-- <b-nav-item class="px-3" :to="dashboardUrl">Dashboard</b-nav-item> -->
        <!-- <b-nav-item class="px-3" to="/company" exact>Create Company</b-nav-item> -->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="d-md-down-none">
          <i class=""></i>
          <b-badge pill variant="danger"></b-badge>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class=""></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class=""></i>
        </b-nav-item>
      </b-navbar-nav>
<!--       <AsideToggler class="d-none d-lg-block" />
 -->      <!--<AsideToggler class="d-lg-none" mobile />-->

      <b-dropdown variant="outline-primary" right split split-href="/logout" class="m-2 mr-3">
        <template slot="button-content">{{ user ? user.username : '' }}</template>
          <b-dropdown-header tag="div" variant="primary" class="text-center"><strong>Account</strong></b-dropdown-header>
          <b-dropdown-item to="/logout">Uitloggen</b-dropdown-item>
      </b-dropdown>
    </AppHeader>
    <div class="app-body bg-meds">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" class="pl-4"/>
        <div class="container-fluid">
          <b-alert
            :show="loadingAlert"
            dismissible
            variant="primary"
            @dismissed="loadingAlert=0"
            @dismiss-count-down="countDownChanged"
            fade
          >
            <p>Checking for new Data...</p>
            <b-progress
              variant="primary"
              :max="loadingSecs"
              :value="loadingAlert"
              height="4px"
            ></b-progress>
          </b-alert>
          <router-view></router-view>
        </div>
      </main>
      <!-- <AppAside fixed>
        aside
        <DefaultAside/>
      </AppAside> -->
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="https://coreui.io">Meds2go</a>
        <span class="ml-1">&copy; 2021 </span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://healthesolutions.nl"><img src="/img/brand/hes-logo.png" height="25" alt="Health E-Solutions Logo"></a>
      </div>
    </TheFooter>

   <!--  <b-toast 
      v-model="loadingToast" 
      id="loading-toast" 
      variant="primary" 
      solid
      no-close-button
      no-auto-hide
      >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Retrieving data</strong>
          <small class="text-muted mr-2"></small>
        </div>
      </template>
      Loading...
    </b-toast>
    <b-toast 
      v-model="doneLoadingToast" 
      id="loading-toast" 
      variant="success" 
      solid
      no-auto-hide
      >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">Done retrieving data</strong>
          <small class="text-muted mr-2"></small>
        </div>
      </template>
      {{toastMessage}}
    </b-toast> -->
  </div>
</template>

<script>
import nav from '@/_nav'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
// import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'

import { mapState, mapGetters, mapActions } from "vuex"

export default {
  name: 'DefaultContainer',
  props: ['from_login'],
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    // AppAside,
    TheFooter,
    Breadcrumb,
    // DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  data: function() {
    return {
      connection: null,
      loadingSecs: 10,
      loadingAlert: 0,
      doneLoadingToast: false,
      toastMessage: "",
    }
  },
  computed: {
    ...mapGetters("auth", ["getDashboardUrl"]),
    ...mapState("auth", {
      user: state => state.current
    }),
    ...mapState("common", {
      fromLogin: state => state.from_login
    }),
    dashboardUrl (){
      return this.getDashboardUrl
    },
    name () {
      return this.$route.path
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    },
    nav() {
        return nav.items.filter(item => {
          let has = item.tag.some(tag => {
              return tag == this.$route.meta.tag
          })
          if (has) return item
        })
    },
    hideSidebar(){
      if (this.emptyObject(this.user)){
        return true
      }else{
        if (this.user.username.toLowerCase() == 'test'){
          return false
        }else {
          return true
        }
      }
    },
  },
  methods: {
    ...mapActions("auth", ["getUserGroups"]),
    ...mapActions("common", ["accessButtons","setFromLogin"]),

    emptyObject(obj){
      return Object.keys(obj).length === 0
    },
    countDownChanged(loadingAlert) {
      this.loadingAlert = loadingAlert
    },
  },
  created(){

  },
  mounted(){
    this.getUserGroups()
    // if(!self.accessButtons){
    if(!self.accessButtons && this.fromLogin){
      let self = this
      this.connection = new WebSocket(`ws://${window.location.host}/api/ws/client/${this.user.id}/`)
        // console.log(this.connection)
        this.connection.onmessage = function(event) {
          self.toastMessage = event.data
          self.accessButtons(true)
          self.setFromLogin(false)
          self.loadingAlert = 0  
        }

        this.connection.onopen = function(event) {
          console.log("Successfully connected to the echo websocket server...")

          // Start pull request
          this.send("Ready")
          self.loadingAlert = self.loadingSecs
         }

         this.connection.onclose = function(event) {
          console.log("Socket Closed")
          self.setFromLogin(false)
         }
    }else if (!this.from_login){
      this.accessButtons(true)
    }
  },
  // beforeRouteEnter (to, from, next) {
  //   if (from['path'] == "/login"){
  //     next({path: to.path,  query: {from_login: 'true'}})
  //   }
  //     next()
  // },
}
</script>
<style>
  .bg-meds::after {
    content: "";
    background-image: url('/img/login_background_Cool-connect-2.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    opacity: 0.4;
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
</style>